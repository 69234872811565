import type { BlockYouTubeVideo } from 'types/block-youtube-video';
import type { Media } from 'types/media';
import React, { memo, useRef, useState } from 'react';
import Image from 'next/image';
import { Wrap } from '@leafwell/components';
import Script from 'next/script';
import { YOUTUBE_IFRAME_SCRIPT_URL, getYoutubeImage } from '../constants';
import HeadingBody from './heading-body';

type ExtendedWindow = Window &
  typeof globalThis & {
    onYouTubeIframeAPIReady: () => void;
    YT: {
      Player(
        name: string,
        options: Record<string, string | number | object>,
      ): void;
    };
  };

const PLAY_STATUS = 1;
const VIDEO_IFRAME_ID = 'iframe-video';

const VideoDecoration: React.FC<{
  playing: boolean;
  variant: string;
}> = ({ playing, variant }) => {
  return (
    <>
      {(variant === 'left' || variant === 'both') && (
        <div
          className={[
            'absolute left-0 bottom-0 hidden lg:block mb-8 ml-8',
            playing ? '-z-1' : '',
          ].join(' ')}
        >
          <svg
            className="absolute lg:bottom-[169px] lg:left-[-20px] 2xl:left-[-80px]"
            fill="none"
            height="63"
            viewBox="0 0 63 63"
            width="63"
            xmlns="http://www.w3.org/2000/svg"
          >
            <radialGradient
              id="a"
              cx="0"
              cy="0"
              gradientTransform="matrix(27.90000858 36.30001272 -36.30001272 27.90000858 20.1 16.2)"
              gradientUnits="userSpaceOnUse"
              r="1"
            >
              <stop offset="0" stopColor="#aaffd8" />
              <stop offset=".747056" stopColor="#36dc90" />
              <stop offset="1" stopColor="#2fb276" />
            </radialGradient>
            <circle cx="31.5" cy="31.5" fill="url(#a)" r="31.5" />
          </svg>
          <svg
            className="absolute lg:bottom-[-18px] lg:left-[-5px] 2xl:left-[-53px]"
            fill="none"
            height="153"
            viewBox="0 0 153 153"
            width="153"
            xmlns="http://www.w3.org/2000/svg"
          >
            <radialGradient
              id="b"
              cx="0"
              cy="0"
              gradientTransform="matrix(67.75730298 88.15735498 -88.15735498 67.75730298 48.8143 39.3429)"
              gradientUnits="userSpaceOnUse"
              r="1"
            >
              <stop offset="0" stopColor="#f1e9ff" />
              <stop offset=".747056" stopColor="#ceb9f3" />
              <stop offset="1" stopColor="#ad90e0" />
            </radialGradient>
            <circle cx="76.5" cy="76.5" fill="url(#b)" r="76.5" />
          </svg>
          <svg
            className="absolute lg:bottom-[2px] lg:left-[200px] 2xl:left-[154px]"
            fill="none"
            height="17"
            viewBox="0 0 17 17"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
          >
            <radialGradient
              id="c"
              cx="0"
              cy="0"
              gradientTransform="matrix(7.52857568 9.79524404 -9.79524404 7.52857568 5.42381 4.37143)"
              gradientUnits="userSpaceOnUse"
              r="1"
            >
              <stop offset="0" stopColor="#fcce97" />
              <stop offset=".747056" stopColor="#e29333" />
              <stop offset="1" stopColor="#996c37" />
            </radialGradient>
            <circle cx="8.5" cy="8.5" fill="url(#c)" r="8.5" />
          </svg>
        </div>
      )}
      {(variant === 'right' || variant === 'both') && (
        <div className="absolute top-0 right-0 -z-1 hidden lg:block mt-8 mr-8">
          <svg
            className="absolute lg:top-[-70px] 2xl:top-[-97px] lg:right-[200px] 2xl:right-[60px]"
            fill="none"
            height="17"
            viewBox="0 0 17 17"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
          >
            <radialGradient
              id="d"
              cx="0"
              cy="0"
              gradientTransform="matrix(7.52857568 9.79524404 -9.79524404 7.52857568 5.42381 4.37143)"
              gradientUnits="userSpaceOnUse"
              r="1"
            >
              <stop offset="0" stopColor="#fcce97" />
              <stop offset=".747056" stopColor="#e29333" />
              <stop offset="1" stopColor="#996c37" />
            </radialGradient>
            <circle cx="8.5" cy="8.5" fill="url(#d)" r="8.5" />
          </svg>
          <svg
            className="absolute lg:top-[-80px] lg:right-[-32px] 2xl:right-[-70px]"
            width="237"
            height="237"
            viewBox="0 0 237 237"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="118.5"
              cy="118.5"
              r="118.5"
              fill="url(#paint0_radial_1462_12111)"
            />
            <radialGradient
              id="paint0_radial_1462_12111"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(75.6143 60.9429) rotate(52.4543) scale(172.232)"
            >
              <stop stopColor="#AAFFD8" />
              <stop offset="0.747056" stopColor="#36DC90" />
              <stop offset="1" stopColor="#2FB276" />
            </radialGradient>
          </svg>
          <svg
            className="absolute lg:top-[188px] lg:right-[-12px] 2xl:right-[-44px]"
            fill="none"
            height="31"
            viewBox="0 0 31 31"
            width="31"
            xmlns="http://www.w3.org/2000/svg"
          >
            <radialGradient
              id="e"
              cx="0"
              cy="0"
              gradientTransform="matrix(13.7285505 17.8618783 -17.8618783 13.7285505 9.89048 7.97143)"
              gradientUnits="userSpaceOnUse"
              r="1"
            >
              <stop offset="0" stopColor="#f1e9ff" />
              <stop offset=".747056" stopColor="#ceb9f3" />
              <stop offset="1" stopColor="#ad90e0" />
            </radialGradient>
            <circle cx="15.5" cy="15.5" fill="url(#e)" r="15.5" />
          </svg>
        </div>
      )}
    </>
  );
};

const VideoContent: React.FC<{
  youtubeVideoId?: string;
  image?: Media;
  playButtonStyle?: string;
  togglePlayState?: (value: boolean) => void;
}> = ({ youtubeVideoId, image, playButtonStyle, togglePlayState }) => {
  const [videoLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayActions = (stateValue?: boolean) => {
    togglePlayState(stateValue);
    setIsPlaying(stateValue);
  };

  return (
    <div className="relative overflow-hidden group w-full aspect-w-16 aspect-h-9 rounded-lg">
      <Image
        src={image?.src || getYoutubeImage(youtubeVideoId)}
        className="md:transform md:transition-transform md:duration-700 md:ease-out md:group-hover:scale-105"
        width={1920}
        height={1080}
        sizes="(max-width: 1023px) 66vw, 100vw"
        style={{
          width: '100%',
          height: 'auto',
        }}
        quality={50}
        alt="Youtube poster"
      />
      {videoLoaded && (
        <>
          <Script
            id="youtube-script"
            type="text/javascript"
            src={YOUTUBE_IFRAME_SCRIPT_URL}
            onReady={() => {
              const extendedWindow = window as ExtendedWindow;
              extendedWindow.onYouTubeIframeAPIReady = () => {
                videoRef.current = new extendedWindow.YT.Player(
                  VIDEO_IFRAME_ID,
                  {
                    videoId: youtubeVideoId,
                    playerVars: {
                      rel: '0',
                      height: '100%',
                      width: '100%',
                    },
                    events: {
                      onStateChange: () => {
                        handlePlayActions(
                          videoRef.current.getPlayerState() === PLAY_STATUS,
                        );
                      },
                      onReady: e => {
                        setIsLoaded(true);
                        e.target.playVideo();
                      },
                      onEnd: () => handlePlayActions(false),
                      onPause: () => handlePlayActions(false),
                      onPlay: () => handlePlayActions(true),
                    },
                  },
                );
              };
            }}
            async
          />
          <div className="absolute w-full h-full">
            <div
              className={[
                'transition-all duration-500 h-full',
                !isPlaying ? 'opacity-0 pointer-events-none' : '',
              ].join(' ')}
            >
              <div id={VIDEO_IFRAME_ID} className="w-full h-full"></div>
            </div>
          </div>
        </>
      )}
      <button
        className={[
          playButtonStyle === 'tertiary'
            ? 'absolute left-[5%] top-[80%] w-fit h-fit'
            : ' grid items-center justify-center',
          'md:transition-all md:hover:opacity-70',
          isPlaying ? 'opacity-0 invisible' : '',
        ].join(' ')}
        onClick={() => {
          videoLoaded ? videoRef?.current?.playVideo() : setIsLoaded(true);
        }}
      >
        {playButtonStyle === 'primary' ? (
          <Image
            src="/play-button.svg"
            width={106}
            height={86}
            alt="Play video"
          />
        ) : playButtonStyle === 'secondary' ? (
          <Image
            src="/play-button-secondary.svg"
            width={70}
            height={70}
            alt="Play video"
          />
        ) : (
          <Image
            src="/play-button-tertiary.svg"
            width={56}
            height={56}
            alt="Play video"
            className="w-6 h-6 lg:w-8 lg:h-8 xl:w-12 xl:h-12"
          />
        )}
      </button>
    </div>
  );
};

const VideoYoutube: React.FC<
  Omit<BlockYouTubeVideo, 'acfFcLayout'> & { removePadding?: boolean }
> = memo(
  ({
    sectionId,
    title,
    content,
    youtubeVideos,
    variant,
    videoSize,
    removePadding = false,
  }) => {
    const [playing, togglePlayState] = useState(false);
    const hasDecoration = variant !== 'none';
    const hasVideos = youtubeVideos?.length > 0;
    const moreThanOne = youtubeVideos?.length > 1;
    const hasTitle = title?.length > 0;

    const getWrapStyles = () => {
      const styles = ['relative'];

      if (moreThanOne) {
        styles.push('md:grid-cols-2 gap-8');
      }

      if (!hasTitle && !removePadding) {
        styles.push('!py-2 md:!py-10 lg:!pt-5');
      }

      if (videoSize === 'small' && !removePadding && moreThanOne) {
        styles.push('lg:px-40');
      }

      if (videoSize === 'small' && !removePadding && !moreThanOne) {
        styles.push('lg:max-w-4xl');
      }

      if (videoSize !== 'small' && !removePadding) {
        styles.push('lg:!pb-20');
      }

      if (removePadding) {
        styles.push('!p-0');
      }

      return styles.join(' ');
    };

    return hasVideos ? (
      <Wrap id={sectionId} className={getWrapStyles()}>
        {title?.length > 0 || content?.length > 0 ? (
          <div className="col-span-full text-center">
            {title?.length > 0 && (
              <HeadingBody className="!mb-2" {...{ level: '2', title }} />
            )}
            {content?.length > 0 ? <p>{content}</p> : null}
          </div>
        ) : null}
        {youtubeVideos?.map(({ youtubeVideoId, image, playButtonStyle }) => (
          <VideoContent
            key={youtubeVideoId}
            youtubeVideoId={youtubeVideoId}
            image={image}
            playButtonStyle={playButtonStyle}
            togglePlayState={togglePlayState}
          />
        ))}
        {hasDecoration && <VideoDecoration {...{ playing, variant }} />}
      </Wrap>
    ) : null;
  },
);

export default VideoYoutube;
